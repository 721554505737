









































import { Component, Vue } from 'vue-property-decorator'
import memberService, { MemberModel, NewMemberModel } from '../../services/memberService'
import msg from '@/components/Msg.vue'
import { MessageText } from '@/services/data'
import UserForm from '@/views/Settings/UserForm.vue'

@Component({
  components: {
    msg,
    UserForm
  }
})
export default class Account extends Vue {
  isLoading = false
  members: MemberModel[] = []
  message: MessageText = null
  userFormDialog = false
  editingMember: MemberModel = null
  isEditing = false

  async mounted () {
    await this.loadUsersAsync()
  }

  async loadUsersAsync () {
    const res = await memberService.getAllAsync()
    if (res.Result) {
      this.members = res.Result
    } else {
      await this.$alert(res.Error)
    }
  }

  addNew () {
    this.editingMember = {
      UserId: 0,
      Name: '',
      LoginId: '',
      RoleId: 1,
      SimpleName: ''
    }
    this.isEditing = false
    this.userFormDialog = true
  }

  editMember (member: MemberModel) {
    this.editingMember = member
    this.isEditing = true
    this.userFormDialog = true
  }

  async deleteMember (member: MemberModel) {
    const res = await memberService.deleteAsync(member.LoginId)
    if (res.Result) {
      await this.loadUsersAsync()
    } else {
      await this.$alert(res.Error)
    }
  }

  async saveUser (member: NewMemberModel) {
    this.userFormDialog = false
    const res = await memberService.addOrUpdateAsync(member)
    if (res.Result) {
      await this.loadUsersAsync()
    } else {
      await this.$alert(res.Error)
    }
  }
}
