


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import validation from '@/common/element-val'
import { NewMemberModel } from '@/services/memberService'

@Component
export default class UserForm extends Vue {
  $refs: {
    modelForm: ElForm;
  }

  @Prop() Member: NewMemberModel
  @Prop() IsEditing: boolean

  model = {
    Name: '',
    LoginId: '',
    RoleId: 1,
    Password: '',
    ConfirmPwd: ''
  }

  rules = {
    Name: validation.requiredRule(),
    LoginId: validation.requiredEmailRule(),
    Password: validation.requiredRule(),
    ConfirmPwd: [{
      validator: validation.required(),
      trigger: 'blur'
    }, {
      validator: validation.fun()(() => {
        return this.model.Password === this.model.ConfirmPwd
      }, 'Password do not match'),
      trigger: 'blur'
    }],
    RoleId: validation.requiredRule()
  }

  mounted () {
    this.model.Name = this.Member.Name
    this.model.LoginId = this.Member.LoginId
    this.model.RoleId = this.Member.RoleId
  }

  @Watch('Member')
  onMemberChanged (v: string) {
    this.model.Name = this.Member.Name
    this.model.LoginId = this.Member.LoginId
    this.model.RoleId = this.Member.RoleId
  }

  // if use @Emit,do not use this.$emit again, @Emit will return the function value to parent
  submit () {
    this.$refs.modelForm.validate((valid: boolean) => {
      if (valid) {
        this.$emit('submit', this.model)
      }
    })
  }
}
